export const PARTNERS = {
    // Heading: Anchor partners
    // Brinton
    // Goqii
    // Nilon
    // Upstox
    // + Exited
    'saral-pay-later': {
        live: [
            { name: '', logo: 'DeHaat.png', color: '' },
            { name: '', logo: 'storeking.png', color: '' },
            { name: '', logo: 'unnati.png', color: '' },
            { name: '', logo: 'zippmat.png', color: '' },
            { name: '', logo: 'yara.svg', color: '' },
            { name: '', logo: 'agrim.png', color: '' },
            { name: '', logo: 'atul.jpg', color: '' },
        ],
        exited: [
            { name: '', logo: 'citymall.png', color: '' },
            { name: '', logo: 'arzooo.png', color: '#000000' },
            { name: '', logo: 'elasticrun.png', color: '' },
            { name: '', logo: 'Jumbotail.png', color: '' },
            { name: '', logo: 'saveo.avif', color: '' },
            { name: '', logo: 'Aquaconnect_logo_new.svg', color: '' },
            { name: '', logo: 'mooofarm.png', color: '' },
            { name: '', logo: 'Locofast.jpg', color: '' },
            { name: '', logo: 'gramophone.png', color: '' },
        ],
    },
    'saral-vendor-finance': {
        live: [
            { name: '', logo: 'AgrostarLogo.png' },
            { name: '', logo: 'Holisol.jpg', color: '' },
            { name: '', logo: 'shreyas-sortex.png', color: '' },
            { name: '', logo: 'munchonn.png', color: '#f6f9ff' },
        ],
        exited: [
            { name: '', logo: 'Fashinza_Logo.png', color: '' },
            { name: '', logo: 'zetwerk-logo@2x.png' },
        ],
    },
    'saral-supply-chain-credit': {
        live: [
            { name: '', logo: 'Brinton-Pharma-Logo.png', color: '' },
            { name: '', logo: 'GenWorks_Logo.png', color: '#d8f9ff' },
            { name: '', logo: 'cocoblu.png', color: '' },
            { name: '', logo: 'GOQii_Logo.png', color: '' },
            { name: '', logo: 'nilons_logo.png', color: '#ffe500' },
            { name: '', logo: 'munchonn.png', color: '#f6f9ff' },
            { name: '', logo: 'NONA.png', color: '#01979c' },
            { name: '', logo: 'poshn.svg', color: '' },
            { name: '', logo: 'Upstox_Logo.png', color: '#5a298c' },
            { name: '', logo: 'BigHaat_Logo.png', color: '' },
        ],
        exited: [
            { name: '', logo: 'all-ways_logistics.png', color: '#000000' },
            { name: '', logo: 'Lal10-Logo-PNG.png', color: '#fdf84b' },
            { name: '', logo: 'Koye.png', color: '' },
        ],
    },
    all: {
        live: [
            { name: '', logo: 'DeHaat.png', color: '' },
            { name: '', logo: 'AgrostarLogo.png' },
            { name: '', logo: 'Brinton-Pharma-Logo.png', color: '' },
            { name: '', logo: 'GOQii_Logo.png', color: '' },
            { name: '', logo: 'nilons_logo.png', color: '#ffe500' },
            // { name: '', logo: 'Upstox_Logo.png', color: '#5a298c' },
            { name: '', logo: 'citymall.png', color: '' },
            { name: '', logo: 'arzooo.png', color: '#000000' },
            { name: '', logo: 'elasticrun.png', color: '' },
            { name: '', logo: 'Jumbotail.png', color: '' },
            { name: '', logo: 'Aquaconnect_logo_new.svg', color: '' },
            { name: '', logo: 'gramophone.png', color: '' },
            { name: '', logo: 'Locofast.jpg', color: '' },
            { name: '', logo: 'mooofarm.png', color: '' },
            { name: '', logo: 'saveo.avif', color: '' },
            { name: '', logo: 'Fashinza_Logo.png', color: '' },
            { name: '', logo: 'zetwerk-logo@2x.png' },
            { name: '', logo: 'all-ways_logistics.png', color: '#000000' },
            { name: '', logo: 'Koye.png', color: '' },
            { name: '', logo: 'Lal10-Logo-PNG.png', color: '#fdf84b' },
        ],
        exited: [
            { name: '', logo: 'citymall.png', color: '' },
            { name: '', logo: 'arzooo.png', color: '#000000' },
            { name: '', logo: 'elasticrun.png', color: '' },
            { name: '', logo: 'Jumbotail.png', color: '' },
            { name: '', logo: 'Aquaconnect_logo_new.svg', color: '' },
            { name: '', logo: 'gramophone.png', color: '' },
            { name: '', logo: 'Locofast.jpg', color: '' },
            { name: '', logo: 'mooofarm.png', color: '' },
            { name: '', logo: 'saveo.avif', color: '' },
            { name: '', logo: 'Fashinza_Logo.png', color: '' },
            { name: '', logo: 'zetwerk-logo@2x.png' },
            { name: '', logo: 'all-ways_logistics.png', color: '#000000' },
            { name: '', logo: 'Koye.png', color: '' },
            { name: '', logo: 'Lal10-Logo-PNG.png', color: '#fdf84b' },
        ],
    },
};
