export const STATS = {
    all: {
        anchors: 105,
        borrowers: 16157,
        invoices: 204196,
        disbursed: 14236180000,
        aum: 2128070000,
    },
    bnpl: {
        anchors: 18,
        borrowers: 8520,
        invoices: 28117,
        disbursed: 5813760000,
        aum: 323859000,
    },
    vendor: {
        anchors: 5,
        borrowers: 106,
        invoices: 1423,
        disbursed: 3623270000,
        aum: 686582000,
    },
    scf: {
        anchors: 11,
        borrowers: 140,
        invoices: 863,
        disbursed: 4799150000,
        aum: 1117629000,
    },
};
