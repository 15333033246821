import React from 'react'
import Container from '../components/atoms/Container'
import Heading from '../components/atoms/Heading'
import Image from '../components/atoms/Image'
import Paragraph from '../components/atoms/Paragraph'
import StaticBanner from '../components/organisms/StaticBanner'
import Section from '../components/organisms/Section'
import Cards from '../components/organisms/Cards'

const SaralSolutions = () => {
    return (
        <>
            <StaticBanner size="xs">
                <Container>
                    <div className="hero-text">
                        <Image className="shape" src='/img/shape-2.png' />
                        <Heading type="h1">Saral Solutions</Heading>
                        <Image className="shape" src='/img/shape-1.png' />
                    </div>
                    <Paragraph>
                        SaralSCF platform provides web-based supply chain loan origination and loan management systems
                        (LOS and LMS) to lending institutions. It enables seamless digital onboarding of vendors and
                        customers, API integration with Anchor's ERP system, streamlined payments and collections
                        mechanisms, invoice-level transaction visibility, and regulatory reporting.
                    </Paragraph>
                </Container>
            </StaticBanner>
            {/* <Container align="center">
                <Heading>
                    Supply Chain Finance
                </Heading>
                <Paragraph className="mt-10">
                    Supply chain financing in India is shifting from traditional methods to a fully integrated solution that
                    offers comprehensive visibility, tracking, payment scheduling, and invoice-level financing cost. The
                    SaralSCF platform provides web-based supply chain loan origination and loan management systems
                    (LOS and LMS) to lending institutions. It enables seamless digital onboarding of vendors and
                    customers, API integration with Anchor's ERP system, streamlined payments and collections
                    mechanisms, invoice-level transaction visibility, and regulatory reporting. This transformation
                    enhances efficiency and transparency in the supply chain financing process, empowering lenders to
                    make informed decisions and effectively manage risk.
                </Paragraph>
            </Container> */}
            <Container>
                <Section config={{
                    align: 'center',
                    items: [
                        {
                            col: 6,
                            colXs: 12,
                            type: 'section',
                            align: 'center',
                            config: {
                                items: [
                                    {
                                        type: 'heading',
                                        config: {
                                            text: 'Supply Chain Credit',
                                            className: 'mb-10'
                                        }
                                    },
                                    {
                                        type: 'paragraphs',
                                        config: {
                                            className: 'mb-10',
                                            items: [
                                                'SaralSCF revolutionizes supply chain financing by providing enterprises with a powerful tool to fuel their growth. Our platform offers flexible supply chain credit solutions, empowering businesses to finance their payables and address short-term working capital needs with ease. With SaralSCF, enterprises can strategically extend their payables while simultaneously unlocking the ability to accelerate payments to suppliers, fostering stronger supplier relationships and operational efficiency.',
                                                'SaralSCF offers short-term supply chain credit specifically designed for fast-growing, scalable, and underserved new economy businesses. These dynamic companies often face challenges in accessing traditional sources of supply chain funding due to their rapid growth and shorter business track record.',
                                                'SaralSCF collaborates with mid-sized enterprises, known as anchors, located in tier I cities, as well as enterprises in tier II and tier III cities that hold significant local market share. These enterprises seek innovative tech-based supply chain financing solutions to drive their business growth.',
                                                'Leveraging its tech-based platform, SaralSCF provides supply chain credit to distributors associated with large established brands. This solution enables distributors to efficiently track and expand their supply chain, fostering their growth and success.'
                                            ]
                                        }
                                    },
                                    {
                                        type: 'stats',
                                        config: {
                                            className: 'mt-20',
                                            items: [
                                                { icon: 'user-line', value: '12', desc: 'Anchors' },
                                                { icon: 'cash-line', value: '₹2250000000', desc: 'Disbursed' },
                                                { icon: 'git-commit-line', value: '₹560000000', desc: 'AUM' },
                                            ]
                                        }
                                    },
                                ],
                            }
                        },
                        {
                            col: 6,
                            colXs: 12,
                            type: 'image',
                            config: {
                                src: 'https://placehold.co/700x1000'
                            }
                        }
                    ]
                }}></Section>
            </Container>
            <Container>
                <Section config={{
                    align: 'center',
                    items: [
                        {
                            col: 6,
                            colXs: 12,
                            type: 'image',
                            config: {
                                src: 'https://placehold.co/700x1000'
                            }
                        },
                        {
                            col: 6,
                            colXs: 12,
                            type: 'section',
                            align: 'center',
                            config: {
                                items: [
                                    {
                                        type: 'heading',
                                        config: {
                                            text: 'Saral Pay Later',
                                            className: 'mb-10'
                                        }
                                    },
                                    {
                                        type: 'paragraphs',
                                        config: {
                                            className: 'mb-10',
                                            items: [
                                                'SaralSCF provides Saral Pay Later (also known as customer or receivables finance) to the customers of an enterprise. ',
                                                'SaralSCF collaborates with enterprises, also referred to as anchors, operating in the thriving new economy sector. We also partner with distributors of nationally recognized branded products and local branded product manufacturers located in tier II and tier III cities. BlackSoil Capital enters into a Credit Line Agreement (CLA) with the Anchors. The CLA covers the Key Fact Statements (KFS). '
                                            ]
                                        }
                                    },
                                    {
                                        type: 'stats',
                                        config: {
                                            className: 'mt-20',
                                            items: [
                                                { icon: 'user-line', value: '75', desc: 'Anchors' },
                                                { icon: 'cash-line', value: '₹4200000000', desc: 'Disbursed' },
                                                { icon: 'git-commit-line', value: '₹35C0000000+', desc: 'AUM' },
                                            ]
                                        }
                                    },
                                    {
                                        type: 'heading',
                                        config: {
                                            className: 'mt-20 mb-20 text-bcpl',
                                            type: 'h5',
                                            text: 'Covering sectors like'
                                        }
                                    },
                                    {
                                        type: 'icon_group',
                                        config: {
                                            items: [
                                                { icon: 'plant-line', desc: 'Agri-tech' },
                                                { icon: 'truck-line', desc: 'Logis-tech' },
                                                { icon: 'tv-line', desc: 'Electronics' },
                                            ]
                                        }
                                    },
                                    {
                                        type: 'icon_group',
                                        config: {
                                            className: 'mt-20',
                                            items: [
                                                { icon: 't-shirt-line', desc: 'Textile' },
                                                { icon: 'capsule-fill', desc: 'Pharma' },
                                                { icon: 'bubble-chart-line', desc: '& a lot more' },
                                            ]
                                        }
                                    },
                                ],
                            }
                        }
                    ]
                }}></Section>
            </Container>
            <Container>
                <Section config={{
                    align: 'center',
                    items: [
                        {
                            col: 6,
                            colXs: 12,
                            type: 'section',
                            align: 'center',
                            config: {
                                items: [
                                    {
                                        type: 'heading',
                                        config: {
                                            text: 'Vendor Finance',
                                            className: 'mb-10'
                                        }
                                    },
                                    {
                                        type: 'paragraphs',
                                        config: {
                                            className: 'mb-10',
                                            items: [
                                                'SaralSCF provides vendor finance (also known as supplier or purchase finance) to the suppliers of an enterprise. ',
                                                'Our vendor credit line serves as a versatile supply chain pool facility, benefiting suppliers associated with anchors such as new economy enterprises, mid-sized enterprises, and distributors. Each credit line is tailored to the unique needs of the anchor, guided by comprehensive Key Fact Statement (KFS).',
                                                'The KFS provides essential details about the credit line, including terms, vendor selection criteria, disbursement and repayment processes, and the roles and responsibilities of the anchor, our platform, lending institution, and vendors. With SaralSCF\'s vendor finance module, we seamlessly capture and manage the distinct KFS for each anchor, ensuring smooth operations and maximizing efficiency'
                                            ]
                                        }
                                    },
                                    {
                                        type: 'stats',
                                        config: {
                                            className: 'mt-20',
                                            items: [
                                                { icon: 'user-line', value: '9', desc: 'Anchors' },
                                                { icon: 'cash-line', value: '₹2940000000', desc: 'Disbursed' },
                                                { icon: 'git-commit-line', value: '₹630000000', desc: 'AUM' },
                                            ]
                                        }
                                    },
                                ],
                            }
                        },
                        {
                            col: 6,
                            colXs: 12,
                            type: 'image',
                            config: {
                                src: 'https://placehold.co/700x1000'
                            }
                        }
                    ]
                }}></Section>
            </Container>
        </>
    )
}

export default SaralSolutions